.major-tag {
  font-size: 1.2em;
  font-weight: 600;
  color: black;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  /* margin: min(0.5rem, 0.2em) 0.1rem; */
  /* border: 1px solid black; */
  text-decoration: underline;
  display: inline-block;
  /* text-decoration: none; */
  transition: all 1s ease-in-out;

  /* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
  &:hover {
    transform: scale(1.04);
  }

  /* support safari and firefox hover*/
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
}

.major-tag-pdf {
  font-size: 1.2em;
  font-weight: 600;
  padding: .05rem .1rem;
  border-radius: 0.5rem;
  margin: 0.1rem 0.1rem;
  background-color: white;
  box-shadow: 0 0 .3rem rgba(0, 0, 0, 0.8);
}