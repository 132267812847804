/* Dark mode styles */
body {
    background-color: #1a1a1a;
    color: #ffffff;
}

form {
    background-color: #333333;
    color: #ffffff;
    padding: 20px;
    * {
        color: #ffffff;
        background-color: #333333;
        padding: 10px;
    }
    br {
        margin-top:10px;
    }
    button[type="submit"] {
        background-color: #4CAF50;
        color: rgb(0, 0, 0);
        padding: 10px 20px;
        margin: 8px 0;
        border: none;
        cursor: pointer;
        border-radius: 8px;
        transition: 0.3s;
    }
    button[type="submit"]:hover {
        background-color: #5adb60;
    }
    button[type="submit"]:active {
        background-color: #4CAF50;
    }

    input[type="text"], input[type="date"], input[type="time"], input[type="password"], input[type="file"], select {
        width: auto;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-sizing: border-box;
        transition: 0.3s;
        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            background-color: #424242;
        }
    }

    input[type="color"]{
        width: 70px;
        height: 50px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-sizing: border-box;
        transition: 0.3s;
        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            background-color: #666666;
        }
    }


    label{
        margin-left: -10px;
    }
    
    .bigBreak {
        margin-top: 20px;
    }

}


