/* Dark mode styles */
body {
    background-color: #1a1a1a;
    color: #ffffff;
}
h1 {
    text-align: center;
    margin: 0;
}
.ManageEvent, .nameImg, .eventContacts {
    color: #ffffff;
    padding: 20px;
    width: 90%;
    margin: auto;
    
    border-radius: 30px;
    * {
        color: #ffffff;
        background-color: transparent;
        padding: 10px;
    }
    h2{
        background-color: transparent;
        margin:0;
    }

    input[type="text"], input[type="date"], input[type="time"], input[type="password"], input[type="file"], select {
        width: auto;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        background-color: #333333;
        border-radius: 8px;
        box-sizing: border-box;
        transition: 0.3s;
        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            background-color: #424242;
        }
    }
   
    input[type="file"] {
        position: relative;
        top: -80px;
        width: 236px;
        margin-left: 20px;
        margin-bottom: -80px;
    }

    input[type="color"]{
        width: 70px;
        height: 50px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-sizing: border-box;
        transition: 0.3s;
        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            background-color: #666666;
        }
    }


    label{
        margin-left: -10px;
    }
    
    .bigBreak {
        margin-top: 20px;
    }
    

}
.eventContacts {
    margin-top: 30px;
}

.confirmButton{
    background-color: #90ffa1;
    color: #1a1a1a;
    &:hover {
        background-color: #5adb60;
    }
    &:active {
        background-color: #4CAF50;
    }
}
.deleteButton{
    background-color: #ff8f8f;
    color: #1a1a1a;
    &:hover {
        background-color: #ff5a5a;
    }
    &:active {
        background-color: #ff4a4a;
    }
}
.editButton{
    background-color: #d3d3d3;
    color: #1a1a1a;
    &:hover {
        background-color: #fafafa;
    }
    &:active {
        background-color: #949494;
    }
}

.zerobreak {
    margin: 0;
    padding: 0;
    height: 0;
}

